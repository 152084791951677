import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { InputGroup, Button, Icon, Notification, Form, FormGroup, FormControl, ControlLabel, ButtonToolbar } from 'rsuite';


import './SignInPage.css'
import { SignUpLink } from '../SignUpPage/SignUpPage';
import { PasswordForgetLink } from '../PasswordForget/PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div>
    <div className="centerSignIn" > <h1>Sign in</h1></div>
    <SignInForm />
    <div className="centerSignIn" > <PasswordForgetLink /></div>
    <div className="centerSignIn" ><SignUpLink /></div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  signInWithGoogle = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(result => { 
        const user=result.user
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(user.uid)
          .set({
            "username": user.displayName,
            "email": user.email,
            "provider": "Google",
            "roles": user.email.endsWith("@toratio.com") ? { "Admin": "Admin" } : {"User":"User"},
            "photoURL":user.photoURL,
            "phoneNumber": user.phoneNumber,
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
        Notification.error({
          title: "Error",
          description: <p>{error.message}</p>
        });
      });

  }




  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((result) => {
        if(result.user.emailVerified){
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);}
        else {   
          this.setState({ ...INITIAL_STATE });
          Notification.error({
            title: "Error",
            description: <p>Email address not verified. Please check your inbox before logging in.</p>
          });
         }
      })
      .catch(error => {
        this.setState({ error });
        Notification.error({
          title: "Error",
          description: <p>{error.message}</p>
        });
      });

  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password } = this.state;

    const isInvalid = password === '' || email === '';


 

    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>

        <Form onSubmit={this.onSubmit}>


          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <InputGroup
              onChange={this.onChange}
              placeholder="Email Address"
            > <FormControl name="email" type="email"  value={email}/><InputGroup.Addon>@
            </InputGroup.Addon>
            </InputGroup></FormGroup>


          <FormGroup>
            <ControlLabel>Password</ControlLabel>
            <InputGroup
              onChange={this.onChange}
              placeholder="Password"
            ><FormControl name="password" type="password" value={password} /><InputGroup.Addon>
                <Icon icon="lock" />
              </InputGroup.Addon>
            </InputGroup></FormGroup>



          <FormGroup>
            <ButtonToolbar className="centerSignIn">
              <Button appearance="primary" disabled={isInvalid} type="submit">Sign in</Button>
            </ButtonToolbar>
          </FormGroup>
          <FormGroup>
            <ButtonToolbar className="centerSignIn">
              <Button onClick={this.signInWithGoogle}> Sign in with <Icon icon="google" /></Button>
            </ButtonToolbar>
          </FormGroup> <br />



        </Form>

      </div>


    );
  }
}



const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };