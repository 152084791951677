import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {  InputGroup, Button,  Notification,Form, FormGroup, FormControl, ControlLabel,ButtonToolbar } from 'rsuite';

import './PasswordForget.css'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
 



const PasswordForgetPage = () => (
  <div>
  <div className="centerForget"><h1>Reset password</h1></div>

    <PasswordForgetForm  />
  </div>
);
 
const INITIAL_STATE = {
  email: '',
  error: null,
};
 
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email } = this.state;
 
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
        Notification.error({
          title: "Error",
          description: <p>{error.message}</p>
        });
      });
 
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email } = this.state;
 
    const isInvalid = email === '';
 
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>

      <Form  onSubmit={this.onSubmit}>


<FormGroup>
    <ControlLabel>Email Address</ControlLabel>
        <InputGroup
               value={email}
              onChange={this.onChange}
              placeholder="Email Address"
            > <FormControl name="email" type="email" />
            <InputGroup.Addon> @ </InputGroup.Addon>
            </InputGroup>
            </FormGroup>


            <FormGroup>
            <ButtonToolbar className="centerForget">
            <Button disabled={isInvalid} type="submit"> Reset My Password</Button>            </ButtonToolbar>
          </FormGroup>
   
 
      </Form> </div>
    );
  }
}
 
const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);
 
export default PasswordForgetPage;
 
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
 
export { PasswordForgetForm, PasswordForgetLink };