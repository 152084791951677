import React, { Component } from 'react';
import { compose } from 'recompose';
import { Table, Avatar, Icon } from 'rsuite';


import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';


const { Column, HeaderCell, Cell } = Table;


class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }


  render() {
    const { users, loading } = this.state;


    return (
     <div>
        <h4>User list</h4>
        {loading && <div>Loading ...</div>}
    
      
        <UserList users={users} />
        </div> 
    );
  }
}


const ImageCell = ({ rowData, dataKey, ...props }) => (

  <Cell {...props}>
    {rowData[dataKey] ? <Avatar size="sm" src={rowData[dataKey]} /> : <Avatar size="sm">  <Icon icon="user" /></Avatar>}
   </Cell>
);

const UserList = ({ users }) => (
<div>
  <Table
    height={500}
    data={users}
    onRowClick={data => {
      console.log(data);
    }}
  >

    
    <Column width={100} >
      <HeaderCell>Avatar</HeaderCell>
      <ImageCell dataKey="photoURL" />
    </Column>

    <Column width={300}>
      <HeaderCell>Name</HeaderCell>
      <Cell dataKey="username" />
    </Column>
    
    <Column width={300}>
      <HeaderCell>Email</HeaderCell>
      <Cell >{rowData => (
        <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
      )}</Cell>
    </Column>

    <Column width={200}>
      <HeaderCell>ID</HeaderCell>
      <Cell dataKey="uid" />
    </Column>

    <Column width={300}>
      <HeaderCell>Type</HeaderCell>
      <Cell >{rowData => (
        <p>{Object.keys(rowData.roles)}</p>
      )}</Cell>
    </Column>
   

  </Table>
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition),
  withFirebase,
)(AdminPage);