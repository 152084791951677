import React, { useState, useEffect } from 'react';
import Breadcrumb from "../Navigation/Breadcrumb.js";
import { Table } from 'rsuite';
import '../App/App.css'


const { Column, HeaderCell, Cell } = Table;


function Dataset(props) {
    const { location } = props;
    const firstDataset = location.pathname.split('/').pop()
    const dataset = firstDataset.split('\\').pop()
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        fetch(
            `/api/datasets?dataset=${dataset}`,
        )
            .then(res => res.json())
            .then(response => {
                setColumns(Object.keys(response[0]));
                setData(response);
                setIsLoading(false);

            })
            .catch(error => console.log(error));

    }, [dataset]);

    




    return (
        <div>
            <div style={{ padding: 10 }}><Breadcrumb /></div>

            {isLoading && <p>Wait I'm Loading datasets for you</p>}
            <div>
                <Table virtualized
                    height={400}
                    data={data}
                   >
                    {columns.map((column,index) =>
                        <Column  key={'column-'.concat(index)} width={100} align="center" fixed>
                            <HeaderCell>{column}</HeaderCell>
                            <Cell dataKey={column} />
                        </Column>

                    )}

                </Table></div>,



        </div>
    );
}


export default Dataset;