import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Panel,Button,Row,Col,Grid } from 'rsuite';


import Breadcrumb from "../Navigation/Breadcrumb.js";
import '../App/App.css';



function App() {

 

  const [datasets, setDatasets] = useState([]);
  const [filesizes, setFilesizes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    fetch(
      `/api/datasets`,
    )
      .then(res => res.json())
      .then(response => {
        setDatasets(response.datasets);
        setFilesizes(response.filesizes);
        setIsLoading(false);
      })
      .catch(error => console.log(error));


  }, []);


 

  return (
    <div>
      <div style={{ padding: 10}}><Breadcrumb/></div>
      {isLoading && <p>Wait I'm Loading datasets for you</p>}
      <div >
      <Grid fluid>

      <Row  gutter={16}>
      
          {datasets.map((c, index) => (
         <Col key={'col'.concat(index)} md={4} sm={12}>
         <Panel   header={c} shaded bordered >

                <p>Size: {filesizes[index]}</p>
                <Link  to={'data/'+c}><Button>Open dataset</Button></Link>
        
            </Panel>
            <br/>
            </Col>
          ))}
          
          </Row>
          </Grid>
      </div>
    </div>
  );
}

export default App;