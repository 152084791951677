import React from 'react';
import { Avatar, Icon, Panel, Divider, Col, FlexboxGrid } from 'rsuite';

import './AccountPage.css'
import { AuthUserContext, withAuthorization } from '../Session';
import PasswordChangeForm from '../PasswordChange/PasswordChange';



const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={8}>


          <Panel shaded>{authUser.photoURL ? <Avatar src={authUser.photoURL} /> : <Avatar>  <Icon icon="user" /></Avatar>}
            <Divider />
            <b>Name :</b> {authUser.username}
            <Divider />
            <b>Email:</b> {authUser.email}
            <Divider />

            <PasswordChangeForm />
            </Panel>

                    </FlexboxGrid.Item>

      </FlexboxGrid>

    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);