import React from 'react';
import { BrowserRouter as Router , Route} from 'react-router-dom';

import ContactPage from "../ContactPage/ContactPage";
import AccountPage from "../AccountPage/AccountPage";
import SignUpPage from "../SignUpPage/SignUpPage";
import SignInPage from "../SignInPage/SignInPage";
import PasswordForgetPage from "../PasswordForget/PasswordForget";
import AdminPage from "../AdminPage/AdminPage";



import Datasets from "../ResultsPage/Data";
import Dataset from "../ResultsPage/Dataset";
import Navigation from "../Navigation/Navigation"
import './App.css';
import Particle from './Particles';
import toratio_logo from '../../constants/toratio_logo.svg';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { Content,Container } from 'rsuite';




  

const App = () => (


    <Container>
        
        <Router>
        
        {/* Navigation */}
        <Particle/>
        <div className="container" >  
        <img class='resize_fit_center' src={toratio_logo} alt="logo" />
        <h1 class='resize_fit_center'>solving problems of the future</h1>
        
        </div>
        
        
        <Content>
              <Route path={ROUTES.CONTACT} component={ContactPage} />
              <Route exact path={ROUTES.DATA} component={Datasets}  />
              <Route  path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route  path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route  path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
    <Route path={ROUTES.ADMIN} component={AdminPage}/>
              <Route path={ROUTES.DATASET} component={Dataset} />
              </Content>
              
        </Router>
        </Container>
       
    

    );
  


export default withAuthentication(App);