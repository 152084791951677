import React from 'react';
import { withFirebase } from '../Firebase';
import { Navbar,Icon,Nav } from 'rsuite';
import { Link } from 'react-router-dom';
import toratio_logo from '../../constants/toratio_logo.svg';
import * as ROUTES from '../../constants/routes';



const NavLink = props => <Nav.Item  componentClass={Link} {...props} />;



const NavigationNonAuth = () => (

  <Navbar  appearance="subtle">
    <Link to={ROUTES.HOME}>
    <Navbar.Header>
    <img src={toratio_logo} alt="logo" height="100%" />
      </Navbar.Header>
      </Link>
   
    <Navbar.Body>
      <Nav pullRight>
      
      <NavLink to ={ROUTES.HOME}  eventKey="1" icon={<Icon icon="home" />}>
      
            Home 
          </NavLink>
          
      <NavLink to={ROUTES.SIGN_IN} eventKey="4" icon={<Icon icon="sign-in" />}>
            Sign in
          </NavLink>
          
      </Nav>
    </Navbar.Body>
  </Navbar>
);
 
export default withFirebase(NavigationNonAuth);