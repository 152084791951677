import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {  InputGroup, Button,  Icon, Notification,Form, FormGroup, FormControl, ControlLabel,ButtonToolbar } from 'rsuite';


import './SignUpPage.css'
import { withFirebase  } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

 
const SignUpPage = () => (
  <div>
        <div className="centerSignUp"> <h1>Sign up</h1></div>

    <SignUpForm />

  </div>
);
 

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    error: null,
  };


  class SignUpFormBase extends Component {
    constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }


 
 
  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = {"User" : "User"};
 
    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }
 
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne) 
      .then(authUser => {
        // Create a user in your Firebase realtime database
        authUser.user.sendEmailVerification()
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            "provider":"Email",
            roles,
            "providerData":null,
            "photoURL":null,
            "phoneNumber": null,

          });
          

      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
        Notification.error({
          title: "Error",
          description: <p>{error.message}</p>
        });
      });
 
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };


 
  render() {
    const {
        username,
        email,
        passwordOne,
        passwordTwo,
      } = this.state;

      const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';
     
     


    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
      <Form onSubmit={this.onSubmit}>

      <FormGroup>
    <ControlLabel>Full name</ControlLabel>
        <InputGroup
              
              onChange={this.onChange}
              placeholder="Full name"
            > <FormControl name="username" type="text" value={username} />
            <InputGroup.Addon> <Icon icon="avatar" /> </InputGroup.Addon>
            </InputGroup>
            </FormGroup>



        <FormGroup>
    <ControlLabel>Email Address</ControlLabel>
        <InputGroup
               
              onChange={this.onChange}
              placeholder="Email Address"
            > <FormControl name="email" type="email"  value={email} />
            <InputGroup.Addon> @ </InputGroup.Addon>
            </InputGroup>
            </FormGroup>



        <FormGroup>
            <ControlLabel>Password</ControlLabel>
            <InputGroup
             
              onChange={this.onChange}
              placeholder="Password"
            ><FormControl  name="passwordOne" type="password" value={passwordOne}/><InputGroup.Addon>
                <Icon icon="lock" />
              </InputGroup.Addon>
            </InputGroup></FormGroup>


        <FormGroup>
            <ControlLabel>Confirm Password</ControlLabel>
            <InputGroup
             
              onChange={this.onChange}
              placeholder="Password"
            ><FormControl  name="passwordTwo" type="password" value={passwordTwo} /><InputGroup.Addon>
                <Icon icon="lock" />
              </InputGroup.Addon>
            </InputGroup></FormGroup>

        <FormGroup>
            <ButtonToolbar className="centerSignIn">
            <Button disabled={isInvalid} type="submit">Sign Up</Button>            </ButtonToolbar>
          </FormGroup>
        
      </Form>
      </div>
    );
  }
}





const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
    withRouter,
    withFirebase,
  )(SignUpFormBase);
 
export default SignUpPage;
 
export { SignUpForm, SignUpLink };