import React from "react";
import { withRouter,Link } from 'react-router-dom';
import { Breadcrumb } from 'rsuite';


const BreadcrumbElement = withRouter(props => {
  const NavLink = props => <Breadcrumb.Item componentClass={Link} {...props} />;

  const { location } = props;
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const pathSnippetsLen = pathSnippets.length;
  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const name = `${pathSnippets.slice(0, index + 1)[pathSnippets.slice(0, index + 1).length-1]}`;
    if (pathSnippetsLen === index+1 ) {
      return ( <Breadcrumb.Item active key={'breadcrumb-'.concat(index)}>{name}</Breadcrumb.Item>)
    } else {
      return ( <NavLink key={'breadcrumb-'.concat(index)} to={url}>{name}</NavLink>)
    }
   
  });

  return (
    <div><Breadcrumb>{breadcrumbItems}</Breadcrumb></div>

  );
});









export default BreadcrumbElement;
