import React, { Component } from 'react';
import {  InputGroup, Button,  Icon, Notification,Form, FormGroup, FormControl, ControlLabel,ButtonToolbar } from 'rsuite';

import { withFirebase } from '../Firebase';
 
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};
 
class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { passwordOne } = this.state;
 
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
        Notification.error({
          title: "Error",
          description: <p>{error.message}</p>
        });
      });

  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
 
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
 
    return (
      <Form onSubmit={this.onSubmit}>
        
        <FormGroup>
            <ControlLabel>New password</ControlLabel>
            <InputGroup
             value={passwordOne}
              onChange={this.onChange}
              placeholder="Password"
            ><FormControl  name="passwordOne" type="password" /><InputGroup.Addon>
                <Icon icon="lock" />
              </InputGroup.Addon>
            </InputGroup></FormGroup>


        <FormGroup>
            <ControlLabel>Confirm new password</ControlLabel>
            <InputGroup
             value={passwordTwo}
              onChange={this.onChange}
              placeholder="Password"
            ><FormControl  name="passwordTwo" type="password" /><InputGroup.Addon>
                <Icon icon="lock" />
              </InputGroup.Addon>
            </InputGroup></FormGroup>

        <FormGroup>
            <ButtonToolbar>
            <Button disabled={isInvalid} type="submit">Change my password</Button>            </ButtonToolbar>
          </FormGroup>





        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}
 
export default withFirebase(PasswordChangeForm);