import React from 'react';
import { withFirebase } from '../Firebase';
import { Header } from 'rsuite';

import * as ROLES from '../../constants/roles';
import NavigationAdmin from './NavigationAdmin';
import NavigationAuth from './NavigationAuth';
import NavigationNonAuth from './NavigationNonAuth';
import { AuthUserContext } from '../Session';



const Navigation = () => (
  <Header>
    <AuthUserContext.Consumer>

      {authUser =>
        authUser ?
          (!!authUser.roles[ROLES.ADMIN] ? <NavigationAdmin /> : <NavigationNonAuth />)
          : <NavigationNonAuth />
      }

    </AuthUserContext.Consumer>

  </Header>
);





export default withFirebase(Navigation);